import berasbermerek from "../images/beras1_75.jpg";
import beras from "../images/beras1_70.jpg";
import minyakmakan from "../images/minyakmakan.jpg";
import sayurkangkung from "../images/kankung.jpg";
import sayurbayam from "../images/bayam.jpeg";
import telur from "../images/telur.jpg";
import pisaucutter from "../images/pisaucutter.jpg";
import gula from "../images/gula.jpg";
import matematika from "../images/matematika.jpg";
import statistika from "../images/statistika.jpg";
import telurayamkampung from "../images/telur_ayamkampung.jpeg";
import konsultasibelajar from "../images/konsultasibelajar.jpg";
import ayam1 from "../images/ayam.png";
import ayam2 from "../images/ayam2.png";
import ayam3 from "../images/ayam3.png";
import ayam4 from "../images/ayam4.png";
import lesmath from "../images/les-math2.webp";
import lesngaji from "../images/ngaji.jpg";
import lesprivate from "../images/les-private.jpg";
import pelatihanstat from "../images/pelatihanstat2.webp";
import pelatihankewirausahaan from "../images/pelatihankewirausahaan2.webp";
import pelatihantilawah from "../images/pelatihantilawah.jpeg";
import pelatihanmatematika from "../images/pelatihanmath.jpg";

const products = [
  {
    id: 1,
    nama_produk: "Beras Bermerek 5 Kg",
    harga: 75000,
    gambar: berasbermerek,
    category: "Bahan Pokok",
    total: 20,
    deskripsi:
      "Harganya 75.000/5kg dengan jumlah barang yang tersedia 20 unit beras",
  },
  {
    id: 2,
    nama_produk: "Beras 5 Kg",
    harga: 70000,
    gambar: beras,
    category: "Bahan Pokok",
    total: 20,
    deskripsi:
      "Harganya 70.000/5kg dengan jumlah barang yang tersedia 20 unit beras",
  },
  {
    id: 3,
    nama_produk: "Minyak Makan",
    harga: 15000,
    gambar: minyakmakan,
    category: "Bahan Pokok",
    total: 20,
    deskripsi:
      "Harganya 15.000/liter dengan jumlah barang yang tersedia 20 liter",
  },
  {
    id: 4,
    nama_produk: "Sayur Kangkung",
    harga: 3000,
    gambar: sayurkangkung,
    category: "Bahan Pokok",
    total: 84,
    deskripsi: "Harganya 3.000/ikat dengan jumlah barang yang tersedia 84 ikat",
  },
  {
    id: 5,
    nama_produk: "Sayur Bayam",
    harga: 3000,
    gambar: sayurbayam,
    category: "Bahan Pokok",
    total: 84,
    deskripsi: "Harganya 3.000/ikat dengan jumlah barang yang tersedia 84 ikat",
  },
  {
    id: 6,
    nama_produk: "Telur",
    harga: 2000,
    gambar: telur,
    category: "Bahan Pokok",
    total: 90,
    deskripsi:
      "Harganya 2000/butir dengan jumlah barang yang tersedia 90 butir",
  },
  {
    id: 7,
    nama_produk: "Telur Ayam Kampung",
    harga: 3000,
    gambar: telurayamkampung,
    category: "Bahan Pokok",
    total: 90,
    deskripsi:
      "Harganya 3000/butir dengan jumlah barang yang tersedia 90 butir",
  },
  {
    id: 8,
    nama_produk: "Pisau Cutter",
    harga: 2500,
    gambar: pisaucutter,
    category: "Bahan Pokok",
    total: 48,
    deskripsi: "Harganya 2.500/pcs dengan jumlah barang yang tersedia 48 pcs",
  },
  {
    id: 9,
    nama_produk: "Gula",
    harga: 18000,
    gambar: gula,
    category: "Bahan Pokok",
    total: 20,
    deskripsi:
      "Harganya 18.000/kg dengan jumlah barang yang tersedia 20kg gula",
  },
  {
    id: 10,
    nama_produk: "Matematika",
    harga: 120000,
    gambar: matematika,
    category: "Modul Belajar",
    total: 1,
    deskripsi: "Buku matematika dengan harga 120.000/buku",
  },
  {
    id: 11,
    nama_produk: "Statistika",
    harga: 120000,
    gambar: statistika,
    category: "Modul Belajar",
    total: 1,
    deskripsi: "Buku matematika dengan harga 120.000/buku",
  },
  {
    id: 12,
    nama_produk: "Bimbel/Les Matematika Siswa SD, SMP, SMA, Mahasiswa",
    harga: 50000,
    gambar: lesmath,
    category: "Bimbel",
    deskripsi:
      "Bimbel/Les Matematika untuk siswa SD, SMP, SMA dan Mahasiswa dengan harga 50.000/pertemuan. ",
  },
  {
    id: 13,
    nama_produk:
      "Melayani konsultasi Jasa Statistika, seperti: Pengolahan dan analisis data, Install Software analysis data, bimbel statistika, dan seterusnya",
    harga: 50000,
    gambar: konsultasibelajar,
    category: "Bimbel",
    deskripsi:
      "Melayani konsultasi Jasa Statistika, seperti: Pengolahan dan analisis data, Install Software analysis data, bimbel statistika, dan seterusnya dengan harga 50.000/pertemuan.",
  },
  {
    id: 14,
    nama_produk: "Menyediakan Bimbingan Tahsin Al-Qur’an dan Tahfiz Al-Qur’an",
    harga: 50000,
    gambar: lesngaji,
    category: "Bimbel",
    deskripsi:
      "Menyediakan Bimbingan Tahsin Al-Qur’an dan Tahfiz Al-Qur’an dengan harga 50.000/pertemuan.",
  },
  {
    id: 15,
    nama_produk:
      "Melayani Jasa Les Privat/Guru datang ke rumah siswa/mahasiswa",
    harga: 50000,
    gambar: lesprivate,
    category: "Bimbel",
    deskripsi:
      "Melayani Jasa Les Privat/Guru datang ke rumah siswa/mahasiswa dengan harga 50.000/pertemuan.",
  },
  {
    id: 16,
    nama_produk: "Ayam Jago",
    harga: 80000,
    gambar: ayam1,
    category: "Ternak Ayam",
    total: 20,
    deskripsi: "Harganya 80.000/ekor ayam jago ",
  },
  {
    id: 17,
    nama_produk: "Ayam Kampung Asli",
    harga: 50000,
    gambar: ayam2,
    category: "Ternak Ayam",
    total: 20,
    deskripsi: "Harganya 50.000/ekor ayam kampung asli",
  },
  {
    id: 18,
    nama_produk: "Ayam Kampung Jantan",
    harga: 75000,
    gambar: ayam3,
    category: "Ternak Ayam",
    total: 20,
    deskripsi: "Harganya 75.000/ekor ayam kampung jantan",
  },
  {
    id: 19,
    nama_produk: "Ayam Kampung Super",
    harga: 45000,
    gambar: ayam4,
    category: "Ternak Ayam",
    total: 20,
    deskripsi: "Harganya 45.000/ekor ayam kampung super",
  },
  {
    id: 20,
    nama_produk: "Pelatihan/Seminar Kewirausahaan",
    harga: 500000,
    gambar: pelatihankewirausahaan,
    category: "Seminar",
    deskripsi:
      "Harganya 500.000/pertemuan untuk Pelatihan/Seminar Kewirausahaan sebagai pemateri. Sebagai peserta 50.000/pertemuan",
  },
  {
    id: 21,
    nama_produk: "Pelatihan/Seminar Tilawah dan Tajwid Al-Qur’an",
    harga: 500000,
    gambar: pelatihantilawah,
    category: "Seminar",
    deskripsi:
      "Harganya 500.000/pertemuan untuk Pelatihan/Seminar Tilawah dan Tajwid Al-Qur’an sebagai pemateri. Sebagai peserta 50.000/pertemuan",
  },
  {
    id: 22,
    nama_produk: "Pelatihan/Seminar Statistika",
    harga: 500000,
    gambar: pelatihanstat,
    category: "Seminar",
    deskripsi:
      "Harganya 500.000/pertemuan untuk Pelatihan/Seminar Statistika. Sebagai peserta 50.000/pertemuan",
  },
  {
    id: 23,
    nama_produk: "Pelatihan/Seminar Matematika",
    harga: 500000,
    gambar: pelatihanmatematika,
    category: "Seminar",
    deskripsi:
      "Harganya 500.000/pertemuan untuk Pelatihan/Seminar Matematika. Sebagai peserta 50.000/pertemuan",
  },
];

export default products;
